import { Box, Td, Text, Tfoot, Tr } from '@chakra-ui/react'

import { Navigation } from '@/components/ui'
import { getCurrentRangeMessage } from '@/utils/pageNavigation'

export interface TableFooterIProps {
  pageCount: number
  pageIndex: number
  pageSize: number
  total: number
  onGoToPage: (pageIndex: number) => void
  onNextPage: () => void
  onPreviousPage: () => void
}

export const TableFooter = ({
  pageCount,
  pageIndex,
  pageSize,
  total,
  onGoToPage,
  onNextPage,
  onPreviousPage,
}: TableFooterIProps) => {
  return (
    <Tfoot>
      <Tr>
        <Td bg='#FFFFFF' colSpan={99} px='16px'>
          <Box
            alignItems='center'
            display='grid'
            fontSize='12px'
            fontWeight={600}
            gridTemplateColumns='repeat(3, 1fr)'
          >
            <Text
              alignSelf='left'
              color='rgba(71, 71, 71, 0.50)'
              fontWeight='medium'
              p='10px'
              textAlign='left'
            >
              {getCurrentRangeMessage(pageIndex, pageSize, total, false)}
            </Text>

            <Navigation
              btnBgColor='#44546F'
              gotoPage={onGoToPage}
              nextPage={onNextPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              previousPage={onPreviousPage}
            />
          </Box>
        </Td>
      </Tr>
    </Tfoot>
  )
}
